<template>
  <div class="HomeProduct">
    <HomePanel title="查询订单">
      <el-form :inline="true" :model="search" class="demo-form-inline">
        <el-form-item label="合同号">
          <el-input v-model="search.contractNo" placeholder="合同编号"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="search.contactInfo" placeholder="手机号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="btnSearch">搜索</el-button>
        </el-form-item>
      </el-form>

      <!-- 面板内容 -->
      <Transition name='fade' ref="target">
        <div v-if="orderList">
          <el-table :data="orderList" border fit style="width: 100%">
            <el-table-column type="expand">
              <template class="expand" #default="props">
                <p style="margin-left: 60px">代理商: {{ props.row.agentNo }}</p>
                <p style="margin-left: 60px">合同编号: {{ props.row.contractNo }}</p>
                <p style="margin-left: 60px">联系方式: {{ props.row.contactInfo }}</p>
              </template>
            </el-table-column>
            <el-table-column label="订单号" align="center" prop="orderNo" />
            <el-table-column label="商品型号" align="center" prop="goodsModel" />
            <el-table-column label="金额" align="center" >
              <template #default="props">&yen;{{props.row.amount / 100}}</template></el-table-column>
            <el-table-column label="创建时间" align="center" prop="createTime" />
            <el-table-column label="付款时间" align="center" prop="finishTime" />
            <el-table-column label="状态" align="center" prop="status" :formatter="statusFormat">
                <template #default="props">
                  <el-tag v-if="props.row.status==='init'" type="danger">未付款</el-tag>
                  <el-tag v-else-if="props.row.status==='processing'" type="danger">付款中</el-tag>
                  <el-tag v-else-if="props.row.status==='success'&& !props.row.refundStatus" type="success">付款完成</el-tag>
                  <el-tag v-else-if="props.row.status==='success'&& props.row.refundStatus === 'refunding'" type="success">退款中</el-tag>
                  <el-tag v-else-if="props.row.status==='success'&& props.row.refundStatus === 'refunded'" type="success">退款完成</el-tag>
                  <el-tag v-else-if="props.row.status==='success'&& props.row.refundStatus === 'failure'" type="warning">退款失败</el-tag>
                  <el-tag v-else-if="props.row.status==='failure'" type="warning">付款失败</el-tag>
                  <el-tag v-else type="info">未知</el-tag>
                </template>
            </el-table-column>
            <el-table-column align="center">
              <template #header>
                操作
              </template>
              <template #default="scope">
                <div v-if="hasRefund(scope.row)">
                  <el-button size="mini" type="danger" @click="handleRefund(scope.$index, scope.row)"
                  >退款申请</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="blank"  v-else>没有搜索到您想要的内容，请核查合同号和手机号是否正确！</div>
        <!--<HomeSkeleton v-else></HomeSkeleton>-->
      </Transition>
    </HomePanel>
  </div>
</template>

<script>
  import { ref, reactive } from "vue";
  import HomePanel from "./../home/components/home-panel.vue";
  import {uselazyData} from '@/hooks/index'
  import { findOrderListByRequired, refundOrder } from "@/api/home";
  import { ElMessage, ElMessageBox } from 'element-plus'
  export default {
      name: "order",
      components: { HomePanel },
      setup() {
        const search = reactive({contractNo: '', contactInfo: ''})
        const orderList = ref([])
        const btnSearch = () =>{
          console.log('search ', search)
          // 仅作非空和长度校验
          if (!search.contractNo || search.contractNo.length > 12) {
            ElMessage({
              message: '请输入正确的合同编号.',
              type: 'warning',
            })
            return
          }
          // let reg= /^\d{11}$/;
          // if (!reg.test(search.contactInfo)) {
          //   ElMessage({
          //     message: '请输入正确的手机号.',
          //     type: 'warning',
          //   })
          //   return
          // }
          findOrderListByRequired(search).then(data => {
            console.log('find order list ', data)
            if (data.code === '1') {
              orderList.value = data.data
            }
          });
        }
        const target = ref(null)
        const statusFormat = (row, column) => {
          console.log(row, column)
          if (row.status === 'init') {
            return '未付款'
          } else if (row.status === 'processing'){
            return '付款中'
          } else if (row.status === 'success') {
            if (!row.refundStatus) {
              return '付款完成'
            } else if (row.refundStatus === 'refunding') {
              return '退款中'
            } else if (row.refundStatus === 'refunded') {
              return '退款完成'
            } else if (row.refundStatus === 'failure') {
              return '退款失败'
            } else {
              return '未知'
            }
          } else if (row.status === 'failure') {
            return '付款失败'
          } else {
            return '未知'
          }

        }
        const hasRefund = (row) => {
          console.log(row)
          if (row.status === 'success' && !row.refundStatus) {
            // 校验付款时间是否超过15分钟
            let dateBegin = new Date(row.finishTime);
            let dateEnd = new Date();
            console.log('dateBegin ', dateBegin)
            console.log('dateEnd ', dateEnd)
            let dateDiff = dateEnd.getTime() - dateBegin.getTime()
            console.log(dateDiff / 1000)
            if (dateDiff / 1000 < 900) {
              return true;
            }
            return false;
          }
          return false;
        }
        const handleRefund = (index, row) => {
          console.log(index, row)
          ElMessageBox.confirm(
            '确认要发起退款吗?',
            '退款确认',
            {
              confirmButtonText: '确认',
              cancelButtonText: '取消',
              type: 'warning',
            }
          )
            .then(() => {
              refundOrder({originalSourceTrace: row.orderNo})
                .then(data => {
                  console.log('refund result ', data)
                  if (data.code === '1') {
                    row.refundStatus = 'refunding'
                    hasRefund(row)
                    ElMessage({
                      type: 'success',
                      message: '退款成功',
                    })
                  } else {
                    ElMessage({
                      type: 'error',
                      message: data.message,
                    })
                  }
                })

            })
            .catch(() => {

            })
        }
        return { search, target, orderList, btnSearch, handleRefund, statusFormat, hasRefund  };
      }
    }
</script>

<style lang="less">
  .search {
    margin: 0 auto;
    border: 1px solid rgba(200, 200, 200, 0.8);
    border-radius: 5px;
    padding-left: 50px;
    p {
      margin-top: 20px;
      font-size: 18px;
      color: #27BA9B;
      display: flex;
      flex-direction: row;
      /*justify-content: flex-start;*/
      label {
        display: block;
        width: 100px;
      }
      input {
        font-size: 16px;
      }
    }
    button {
      margin-top: 10px;
      margin-bottom: 20px;
      padding: 2px 8px;
      font-size: 18px;
      background-color: blueviolet;
      border: 0;
      color: white;
      border-radius: 5px;
    }
  }
  .goods-detail {
    margin: 20px auto;
    width: 100%;
    border: 1px solid rgba(200, 200, 200, 0.8);
    border-radius: 5px;
    padding-bottom: 20px;
    .title {
      width: 80%;
      font-size: 18px;
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      span {
        margin-left: 200px;
        width: 180px;
        display: block;
        text-align: left;
        color: #27BA9B;
      }
    }
    .price {
      color: @priceColor;
    }

  }
  .blank {
    margin: 30px auto;
    padding-bottom: 20px;
    width: 80%;
    text-align: center;
    color: #ccc;
    font-size: 16px;
  }
 .el-button--primary{
    background-color: #ff6000;
    border-color: #ff3600;
}
.el-button--primary:focus, .el-button--primary:hover {
    background-color: #ff6000;
    border-color: #ff3600;
    color: #FFF;
}
.el-table th.el-table__cell.is-leaf{
  background-color: #f0f3f8 !important;
}
</style>
